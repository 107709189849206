jQuery(document).ready(function($){

    $('.is-megamenu-parent > a[href^="#"]').on('click mouseover', function(event) {
        event.preventDefault();
        event.stopPropagation();

        var menu_id = $(this).attr('href');
        var pos = menu_id.indexOf('#');

        if (pos != -1) {
            var slug = menu_id.substring(pos + 1);
            var $parent = $(this).parents('.is-dropdown-submenu-parent');
            var was_active = $parent.hasClass('is-active');

            $('.megamenu.is-active:not([data-megamenu="' + slug + '"]').removeClass('is-active').slideToggle('fast');
            $('.is-megamenu-parent').removeClass('is-active');

            if (!was_active) {
                $parent.toggleClass('is-active');
            }
            $('.megamenu[data-megamenu="' + slug + '"]').toggleClass('is-active').slideToggle('fast');
        }
    });

    $('.megamenu').on('mouseover', function (event) {
        event.stopPropagation();
    });

    $('body').on('click mouseover', function () {
        $('.is-megamenu-parent').removeClass('is-active');
        $('.megamenu.is-active').removeClass('is-active').slideToggle('fast');
    });

});
